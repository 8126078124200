import SvgDiagram from "./SvgDiagram";

export default function SvgCard({totalRecords, assets_with_errors, in_gis_but_missing_in_sap, in_sap_but_missing_in_gis}) {
    return(
<div class="card d-flex flex-column h-100">
<div className="card-header" style={{backgroundColor:"#18113E"}}>
    <h4>GIS / SAP Matching</h4>
</div>
<div className="card-body">
    <div className="row h-100">
        <div className="col-12 pt-4 h-100">
            {/* <div className="svg--chart">
                <div className="linked--assets">
                    <p style={{margin:0, textAlign:"center"}}>
                    <span className="bold" style={{marginRight:"20px"}}>GIS</span>
                    <span className="figure">{totalRecords - in_gis_but_missing_in_sap}</span>
                    <span className="figure-label" style={{display:"inline-block", width:"50px", textTransform:"uppercase"}}>Linked
                    ASSETS</span>
                    <span className="bold" style={{marginLeft:"20px"}}>SAP</span>

                    </p>
                </div>
                <div className="asset--errors">
                <p style={{margin:0, textAlign:"center"}}>
                    <span className="figure">{assets_with_errors}</span>
                        <span className="figure-label" style={{display:"inline-block", width:"90px", textTransform:"uppercase", textAlign:"start", marginLeft:"5px"}}>Assets with
                        Errors</span>
                </p>
                </div>
                <div className="green-bg h-100 w-50">
                    <div className="missing-sap p-absolute">
                        <span className="figure-label" style={{width:"90px", textAlign:"end", display:"inline-block"}}>In GIS But Missing in SAP</span>
                        <span className="figure" style={{marginLeft:"7px"}}>{in_gis_but_missing_in_sap}</span>
                    </div>
                </div>
                <div className="blue-bg h-100 w-50">
                <div className="missing-gis p-absolute">
                <span className="figure" style={{marginRight:"7px"}}>{in_sap_but_missing_in_gis}</span>
                <span className="figure-label" style={{width:"90px", textAlign:"start", display:"inline-block"}}>In SAP But Missing in GIS</span>
                </div>
                </div>
            </div> */}
            <SvgDiagram 
            in_gis_but_missing_in_sap={in_gis_but_missing_in_sap}
            totalRecords={totalRecords} 
            assets_with_errors={assets_with_errors}
            in_gis_but_missing_in_sap={in_gis_but_missing_in_sap}
            in_sap_but_missing_in_gis={in_sap_but_missing_in_gis}
            />
        </div>
    </div>
</div>
</div>
    )
}