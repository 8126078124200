export default function SvgDiagram({totalRecords, assets_with_errors, in_gis_but_missing_in_sap, in_sap_but_missing_in_gis}) {
    return (
        <svg width="385" height="282" viewBox="0 0 385 282" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="GIS_SAP_matching-asset">
                <rect id="Rectangle 34" x="0.5" y="0.5" width="192" height="55" rx="4.5" fill="#7EC791" fill-opacity="0.7" stroke="black" stroke-dasharray="8 8" />
                <rect id="Rectangle 35" x="193.5" y="226.5" width="191" height="55" rx="4.5" fill="#0085CE" fill-opacity="0.7" stroke="black" stroke-dasharray="8 8" />
                <path id="Rectangle 32" d="M1 54C1 51.2386 3.23858 49 6 49H193V233H6C3.23858 233 1 230.761 1 228V54Z" fill="#7EC791" />
                <path id="Rectangle 33" d="M384 56C384 53.2386 381.761 51 379 51H193V235H379C381.761 235 384 232.761 384 230V56Z" fill="#0085CE" />
                <text id="Figure - In GIS" fill="#18113E"  font-family="Avenir" font-size="29" letter-spacing="0em"><tspan x="124.329" y="33.693">{in_gis_but_missing_in_sap}</tspan></text>
                <text id="Figures - In SAP" fill="#18113E"   font-family="Avenir" font-size="29" letter-spacing="0em"><tspan x="217.329" y="266.693">{in_sap_but_missing_in_gis}</tspan></text>
                <rect id="Rectangle 18" x="118" y="164" width="149" height="46" rx="4" fill="#F6C2CB" stroke="black" stroke-width="2" stroke-dasharray="8 8" />
                <text id="Figures Integrity Errors" fill="black"   font-family="Avenir" font-size="29" letter-spacing="0em"><tspan x="129.329" y="196.693">{assets_with_errors}</tspan></text>
                <rect id="Rectangle 31" x="1.5" y="49.5" width="382" height="183" rx="3.5" stroke="#18113E" stroke-width="3" />
                <rect id="Rectangle 18_2" x="28" y="96" width="329" height="48" rx="24" fill="white" fill-opacity="0.5" />
                <g id="Vector">
                    <path d="M280.016 253H279.152V244.504H280.016V253Z" fill="#18113E" />
                    <path d="M288.46 251.704H288.484V244.504H289.348V253H288.268L283.204 245.728H283.18V253H282.316V244.504H283.396L288.46 251.704Z" fill="#18113E" />
                    <path d="M299.016 245.908C298.64 245.356 298.092 245.08 297.372 245.08C297.148 245.08 296.928 245.112 296.712 245.176C296.496 245.232 296.3 245.324 296.124 245.452C295.956 245.572 295.82 245.732 295.716 245.932C295.612 246.124 295.56 246.352 295.56 246.616C295.56 247 295.668 247.3 295.884 247.516C296.1 247.724 296.368 247.896 296.688 248.032C297.008 248.168 297.356 248.292 297.732 248.404C298.116 248.508 298.468 248.652 298.788 248.836C299.108 249.012 299.376 249.248 299.592 249.544C299.808 249.84 299.916 250.244 299.916 250.756C299.916 251.14 299.832 251.488 299.664 251.8C299.504 252.104 299.288 252.36 299.016 252.568C298.752 252.776 298.452 252.936 298.116 253.048C297.788 253.16 297.456 253.216 297.12 253.216C296.6 253.216 296.104 253.12 295.632 252.928C295.168 252.728 294.772 252.404 294.444 251.956L295.224 251.392C295.408 251.704 295.664 251.956 295.992 252.148C296.32 252.332 296.708 252.424 297.156 252.424C297.372 252.424 297.588 252.392 297.804 252.328C298.02 252.256 298.212 252.156 298.38 252.028C298.556 251.892 298.7 251.728 298.812 251.536C298.924 251.344 298.98 251.124 298.98 250.876C298.98 250.58 298.916 250.336 298.788 250.144C298.668 249.944 298.504 249.78 298.296 249.652C298.096 249.516 297.864 249.404 297.6 249.316C297.344 249.228 297.076 249.14 296.796 249.052C296.524 248.964 296.256 248.864 295.992 248.752C295.736 248.64 295.504 248.5 295.296 248.332C295.096 248.156 294.932 247.94 294.804 247.684C294.684 247.42 294.624 247.092 294.624 246.7C294.624 246.292 294.7 245.936 294.852 245.632C295.012 245.328 295.22 245.076 295.476 244.876C295.74 244.676 296.036 244.528 296.364 244.432C296.692 244.336 297.028 244.288 297.372 244.288C297.852 244.288 298.288 244.364 298.68 244.516C299.072 244.668 299.436 244.936 299.772 245.32L299.016 245.908Z" fill="#18113E" />
                    <path d="M302.78 249.976H306.464L304.628 245.512L302.78 249.976ZM301.52 253H300.512L304.208 244.504H305.048L308.744 253H307.736L306.8 250.768H302.456L301.52 253Z" fill="#18113E" />
                    <path d="M310.754 248.26H312.146C312.53 248.26 312.854 248.22 313.118 248.14C313.39 248.06 313.606 247.956 313.766 247.828C313.926 247.692 314.042 247.536 314.114 247.36C314.186 247.176 314.222 246.98 314.222 246.772C314.222 246.572 314.186 246.384 314.114 246.208C314.042 246.024 313.926 245.864 313.766 245.728C313.606 245.592 313.39 245.488 313.118 245.416C312.854 245.336 312.53 245.296 312.146 245.296H310.754V248.26ZM309.89 244.504H312.362C313.226 244.504 313.906 244.692 314.402 245.068C314.906 245.444 315.158 246.012 315.158 246.772C315.158 247.532 314.906 248.104 314.402 248.488C313.906 248.864 313.226 249.052 312.362 249.052H310.754V253H309.89V244.504Z" fill="#18113E" />
                    <path d="M320.985 252.208H322.641C322.953 252.208 323.245 252.176 323.517 252.112C323.789 252.048 324.025 251.952 324.225 251.824C324.425 251.688 324.581 251.52 324.693 251.32C324.813 251.12 324.873 250.88 324.873 250.6C324.873 250.04 324.693 249.624 324.333 249.352C323.973 249.08 323.445 248.944 322.749 248.944H320.985V252.208ZM320.985 248.224H322.725C322.933 248.224 323.137 248.2 323.337 248.152C323.545 248.096 323.729 248.008 323.889 247.888C324.057 247.768 324.193 247.616 324.297 247.432C324.401 247.24 324.453 247.008 324.453 246.736C324.453 246.648 324.433 246.524 324.393 246.364C324.361 246.204 324.281 246.044 324.153 245.884C324.033 245.724 323.853 245.588 323.613 245.476C323.373 245.356 323.049 245.296 322.641 245.296H320.985V248.224ZM320.121 244.504H322.653C323.005 244.504 323.345 244.54 323.673 244.612C324.001 244.684 324.293 244.804 324.549 244.972C324.805 245.14 325.009 245.36 325.161 245.632C325.313 245.904 325.389 246.24 325.389 246.64C325.389 247.104 325.257 247.504 324.993 247.84C324.737 248.168 324.369 248.388 323.889 248.5V248.524C324.489 248.572 324.957 248.784 325.293 249.16C325.637 249.528 325.809 250.024 325.809 250.648C325.809 250.784 325.773 250.984 325.701 251.248C325.637 251.512 325.497 251.776 325.281 252.04C325.065 252.296 324.753 252.52 324.345 252.712C323.937 252.904 323.393 253 322.713 253H320.121V244.504Z" fill="#18113E" />
                    <path d="M333.751 249.832C333.751 250.184 333.707 250.56 333.619 250.96C333.539 251.352 333.383 251.716 333.151 252.052C332.919 252.388 332.599 252.668 332.191 252.892C331.791 253.108 331.275 253.216 330.643 253.216C330.003 253.216 329.483 253.108 329.083 252.892C328.683 252.668 328.367 252.388 328.135 252.052C327.903 251.716 327.743 251.352 327.655 250.96C327.575 250.56 327.535 250.184 327.535 249.832V244.504H328.399V249.736C328.399 250.144 328.439 250.496 328.519 250.792C328.599 251.08 328.703 251.324 328.831 251.524C328.967 251.724 329.115 251.884 329.275 252.004C329.443 252.116 329.607 252.204 329.767 252.268C329.935 252.332 330.095 252.376 330.247 252.4C330.399 252.416 330.531 252.424 330.643 252.424C330.747 252.424 330.875 252.416 331.027 252.4C331.187 252.376 331.347 252.332 331.507 252.268C331.675 252.204 331.839 252.116 331.999 252.004C332.167 251.884 332.315 251.724 332.443 251.524C332.579 251.324 332.687 251.08 332.767 250.792C332.847 250.496 332.887 250.144 332.887 249.736V244.504H333.751V249.832Z" fill="#18113E" />
                    <path d="M338.63 253H337.766V245.296H334.922V244.504H341.474V245.296H338.63V253Z" fill="#18113E" />
                    <path d="M283.34 267.416L286.208 260.504H287.528V269H286.664V261.656H286.64L283.568 269H283.112L280.04 261.656H280.016V269H279.152V260.504H280.472L283.34 267.416Z" fill="#18113E" />
                    <path d="M290.692 269H289.828V260.504H290.692V269Z" fill="#18113E" />
                    <path d="M297.012 261.908C296.636 261.356 296.088 261.08 295.368 261.08C295.144 261.08 294.924 261.112 294.708 261.176C294.492 261.232 294.296 261.324 294.12 261.452C293.952 261.572 293.816 261.732 293.712 261.932C293.608 262.124 293.556 262.352 293.556 262.616C293.556 263 293.664 263.3 293.88 263.516C294.096 263.724 294.364 263.896 294.684 264.032C295.004 264.168 295.352 264.292 295.728 264.404C296.112 264.508 296.464 264.652 296.784 264.836C297.104 265.012 297.372 265.248 297.588 265.544C297.804 265.84 297.912 266.244 297.912 266.756C297.912 267.14 297.828 267.488 297.66 267.8C297.5 268.104 297.284 268.36 297.012 268.568C296.748 268.776 296.448 268.936 296.112 269.048C295.784 269.16 295.452 269.216 295.116 269.216C294.596 269.216 294.1 269.12 293.628 268.928C293.164 268.728 292.768 268.404 292.44 267.956L293.22 267.392C293.404 267.704 293.66 267.956 293.988 268.148C294.316 268.332 294.704 268.424 295.152 268.424C295.368 268.424 295.584 268.392 295.8 268.328C296.016 268.256 296.208 268.156 296.376 268.028C296.552 267.892 296.696 267.728 296.808 267.536C296.92 267.344 296.976 267.124 296.976 266.876C296.976 266.58 296.912 266.336 296.784 266.144C296.664 265.944 296.5 265.78 296.292 265.652C296.092 265.516 295.86 265.404 295.596 265.316C295.34 265.228 295.072 265.14 294.792 265.052C294.52 264.964 294.252 264.864 293.988 264.752C293.732 264.64 293.5 264.5 293.292 264.332C293.092 264.156 292.928 263.94 292.8 263.684C292.68 263.42 292.62 263.092 292.62 262.7C292.62 262.292 292.696 261.936 292.848 261.632C293.008 261.328 293.216 261.076 293.472 260.876C293.736 260.676 294.032 260.528 294.36 260.432C294.688 260.336 295.024 260.288 295.368 260.288C295.848 260.288 296.284 260.364 296.676 260.516C297.068 260.668 297.432 260.936 297.768 261.32L297.012 261.908Z" fill="#18113E" />
                    <path d="M303.68 261.908C303.304 261.356 302.756 261.08 302.036 261.08C301.812 261.08 301.592 261.112 301.376 261.176C301.16 261.232 300.964 261.324 300.788 261.452C300.62 261.572 300.484 261.732 300.38 261.932C300.276 262.124 300.224 262.352 300.224 262.616C300.224 263 300.332 263.3 300.548 263.516C300.764 263.724 301.032 263.896 301.352 264.032C301.672 264.168 302.02 264.292 302.396 264.404C302.78 264.508 303.132 264.652 303.452 264.836C303.772 265.012 304.04 265.248 304.256 265.544C304.472 265.84 304.58 266.244 304.58 266.756C304.58 267.14 304.496 267.488 304.328 267.8C304.168 268.104 303.952 268.36 303.68 268.568C303.416 268.776 303.116 268.936 302.78 269.048C302.452 269.16 302.12 269.216 301.784 269.216C301.264 269.216 300.768 269.12 300.296 268.928C299.832 268.728 299.436 268.404 299.108 267.956L299.888 267.392C300.072 267.704 300.328 267.956 300.656 268.148C300.984 268.332 301.372 268.424 301.82 268.424C302.036 268.424 302.252 268.392 302.468 268.328C302.684 268.256 302.876 268.156 303.044 268.028C303.22 267.892 303.364 267.728 303.476 267.536C303.588 267.344 303.644 267.124 303.644 266.876C303.644 266.58 303.58 266.336 303.452 266.144C303.332 265.944 303.168 265.78 302.96 265.652C302.76 265.516 302.528 265.404 302.264 265.316C302.008 265.228 301.74 265.14 301.46 265.052C301.188 264.964 300.92 264.864 300.656 264.752C300.4 264.64 300.168 264.5 299.96 264.332C299.76 264.156 299.596 263.94 299.468 263.684C299.348 263.42 299.288 263.092 299.288 262.7C299.288 262.292 299.364 261.936 299.516 261.632C299.676 261.328 299.884 261.076 300.14 260.876C300.404 260.676 300.7 260.528 301.028 260.432C301.356 260.336 301.692 260.288 302.036 260.288C302.516 260.288 302.952 260.364 303.344 260.516C303.736 260.668 304.1 260.936 304.436 261.32L303.68 261.908Z" fill="#18113E" />
                    <path d="M307.192 269H306.328V260.504H307.192V269Z" fill="#18113E" />
                    <path d="M315.636 267.704H315.66V260.504H316.524V269H315.444L310.38 261.728H310.356V269H309.492V260.504H310.572L315.636 267.704Z" fill="#18113E" />
                    <path d="M325.108 262.064C324.82 261.76 324.476 261.52 324.076 261.344C323.676 261.168 323.212 261.08 322.684 261.08C322.14 261.08 321.652 261.18 321.22 261.38C320.788 261.58 320.42 261.848 320.116 262.184C319.82 262.52 319.592 262.912 319.432 263.36C319.272 263.8 319.192 264.264 319.192 264.752C319.192 265.24 319.272 265.708 319.432 266.156C319.592 266.596 319.82 266.984 320.116 267.32C320.42 267.656 320.788 267.924 321.22 268.124C321.652 268.324 322.14 268.424 322.684 268.424C323.124 268.424 323.544 268.372 323.944 268.268C324.352 268.156 324.716 268.008 325.036 267.824V264.98H323.008V264.188H325.9V268.352C325.42 268.632 324.892 268.848 324.316 269C323.748 269.144 323.204 269.216 322.684 269.216C322.052 269.216 321.464 269.104 320.92 268.88C320.384 268.656 319.916 268.348 319.516 267.956C319.124 267.556 318.816 267.084 318.592 266.54C318.368 265.996 318.256 265.4 318.256 264.752C318.256 264.104 318.368 263.508 318.592 262.964C318.816 262.42 319.124 261.952 319.516 261.56C319.916 261.16 320.384 260.848 320.92 260.624C321.464 260.4 322.052 260.288 322.684 260.288C323.364 260.288 323.948 260.384 324.436 260.576C324.932 260.76 325.372 261.044 325.756 261.428L325.108 262.064Z" fill="#18113E" />
                    <path d="M332.375 269H331.511V260.504H332.375V269Z" fill="#18113E" />
                    <path d="M340.819 267.704H340.843V260.504H341.707V269H340.627L335.563 261.728H335.539V269H334.675V260.504H335.755L340.819 267.704Z" fill="#18113E" />
                    <path d="M353.631 262.064C353.343 261.76 352.999 261.52 352.599 261.344C352.199 261.168 351.735 261.08 351.207 261.08C350.663 261.08 350.175 261.18 349.743 261.38C349.311 261.58 348.943 261.848 348.639 262.184C348.343 262.52 348.115 262.912 347.955 263.36C347.795 263.8 347.715 264.264 347.715 264.752C347.715 265.24 347.795 265.708 347.955 266.156C348.115 266.596 348.343 266.984 348.639 267.32C348.943 267.656 349.311 267.924 349.743 268.124C350.175 268.324 350.663 268.424 351.207 268.424C351.647 268.424 352.067 268.372 352.467 268.268C352.875 268.156 353.239 268.008 353.559 267.824V264.98H351.531V264.188H354.423V268.352C353.943 268.632 353.415 268.848 352.839 269C352.271 269.144 351.727 269.216 351.207 269.216C350.575 269.216 349.987 269.104 349.443 268.88C348.907 268.656 348.439 268.348 348.039 267.956C347.647 267.556 347.339 267.084 347.115 266.54C346.891 265.996 346.779 265.4 346.779 264.752C346.779 264.104 346.891 263.508 347.115 262.964C347.339 262.42 347.647 261.952 348.039 261.56C348.439 261.16 348.907 260.848 349.443 260.624C349.987 260.4 350.575 260.288 351.207 260.288C351.887 260.288 352.471 260.384 352.959 260.576C353.455 260.76 353.895 261.044 354.279 261.428L353.631 262.064Z" fill="#18113E" />
                    <path d="M357.559 269H356.695V260.504H357.559V269Z" fill="#18113E" />
                    <path d="M363.879 261.908C363.503 261.356 362.955 261.08 362.235 261.08C362.011 261.08 361.791 261.112 361.575 261.176C361.359 261.232 361.163 261.324 360.987 261.452C360.819 261.572 360.683 261.732 360.579 261.932C360.475 262.124 360.423 262.352 360.423 262.616C360.423 263 360.531 263.3 360.747 263.516C360.963 263.724 361.231 263.896 361.551 264.032C361.871 264.168 362.219 264.292 362.595 264.404C362.979 264.508 363.331 264.652 363.651 264.836C363.971 265.012 364.239 265.248 364.455 265.544C364.671 265.84 364.779 266.244 364.779 266.756C364.779 267.14 364.695 267.488 364.527 267.8C364.367 268.104 364.151 268.36 363.879 268.568C363.615 268.776 363.315 268.936 362.979 269.048C362.651 269.16 362.319 269.216 361.983 269.216C361.463 269.216 360.967 269.12 360.495 268.928C360.031 268.728 359.635 268.404 359.307 267.956L360.087 267.392C360.271 267.704 360.527 267.956 360.855 268.148C361.183 268.332 361.571 268.424 362.019 268.424C362.235 268.424 362.451 268.392 362.667 268.328C362.883 268.256 363.075 268.156 363.243 268.028C363.419 267.892 363.563 267.728 363.675 267.536C363.787 267.344 363.843 267.124 363.843 266.876C363.843 266.58 363.779 266.336 363.651 266.144C363.531 265.944 363.367 265.78 363.159 265.652C362.959 265.516 362.727 265.404 362.463 265.316C362.207 265.228 361.939 265.14 361.659 265.052C361.387 264.964 361.119 264.864 360.855 264.752C360.599 264.64 360.367 264.5 360.159 264.332C359.959 264.156 359.795 263.94 359.667 263.684C359.547 263.42 359.487 263.092 359.487 262.7C359.487 262.292 359.563 261.936 359.715 261.632C359.875 261.328 360.083 261.076 360.339 260.876C360.603 260.676 360.899 260.528 361.227 260.432C361.555 260.336 361.891 260.288 362.235 260.288C362.715 260.288 363.151 260.364 363.543 260.516C363.935 260.668 364.299 260.936 364.635 261.32L363.879 261.908Z" fill="#18113E" />
                    <path d="M46.6449 20.0001H45.7809V11.5041H46.6449V20.0001Z" fill="#18113E" />
                    <path d="M55.0889 18.7041H55.1129V11.5041H55.9769V20.0001H54.8969L49.8329 12.7281H49.8089V20.0001H48.9449V11.5041H50.0249L55.0889 18.7041Z" fill="#18113E" />
                    <path d="M67.9006 13.0641C67.6126 12.7601 67.2686 12.5201 66.8686 12.3441C66.4686 12.1681 66.0046 12.0801 65.4766 12.0801C64.9326 12.0801 64.4446 12.1801 64.0126 12.3801C63.5806 12.5801 63.2126 12.8481 62.9086 13.1841C62.6126 13.5201 62.3846 13.9121 62.2246 14.3601C62.0646 14.8001 61.9846 15.2641 61.9846 15.7521C61.9846 16.2401 62.0646 16.7081 62.2246 17.1561C62.3846 17.5961 62.6126 17.9841 62.9086 18.3201C63.2126 18.6561 63.5806 18.9241 64.0126 19.1241C64.4446 19.3241 64.9326 19.4241 65.4766 19.4241C65.9166 19.4241 66.3366 19.3721 66.7366 19.2681C67.1446 19.1561 67.5086 19.0081 67.8286 18.8241V15.9801H65.8006V15.1881H68.6926V19.3521C68.2126 19.6321 67.6846 19.8481 67.1086 20.0001C66.5406 20.1441 65.9966 20.2161 65.4766 20.2161C64.8446 20.2161 64.2566 20.1041 63.7126 19.8801C63.1766 19.6561 62.7086 19.3481 62.3086 18.9561C61.9166 18.5561 61.6086 18.0841 61.3846 17.5401C61.1606 16.9961 61.0486 16.4001 61.0486 15.7521C61.0486 15.1041 61.1606 14.5081 61.3846 13.9641C61.6086 13.4201 61.9166 12.9521 62.3086 12.5601C62.7086 12.1601 63.1766 11.8481 63.7126 11.6241C64.2566 11.4001 64.8446 11.2881 65.4766 11.2881C66.1566 11.2881 66.7406 11.3841 67.2286 11.5761C67.7246 11.7601 68.1646 12.0441 68.5486 12.4281L67.9006 13.0641Z" fill="#18113E" />
                    <path d="M71.8285 20.0001H70.9645V11.5041H71.8285V20.0001Z" fill="#18113E" />
                    <path d="M78.1485 12.9081C77.7725 12.3561 77.2245 12.0801 76.5045 12.0801C76.2805 12.0801 76.0605 12.1121 75.8445 12.1761C75.6285 12.2321 75.4325 12.3241 75.2565 12.4521C75.0885 12.5721 74.9525 12.7321 74.8485 12.9321C74.7445 13.1241 74.6925 13.3521 74.6925 13.6161C74.6925 14.0001 74.8005 14.3001 75.0165 14.5161C75.2325 14.7241 75.5005 14.8961 75.8205 15.0321C76.1405 15.1681 76.4885 15.2921 76.8645 15.4041C77.2485 15.5081 77.6005 15.6521 77.9205 15.8361C78.2405 16.0121 78.5085 16.2481 78.7245 16.5441C78.9405 16.8401 79.0485 17.2441 79.0485 17.7561C79.0485 18.1401 78.9645 18.4881 78.7965 18.8001C78.6365 19.1041 78.4205 19.3601 78.1485 19.5681C77.8845 19.7761 77.5845 19.9361 77.2485 20.0481C76.9205 20.1601 76.5885 20.2161 76.2525 20.2161C75.7325 20.2161 75.2365 20.1201 74.7645 19.9281C74.3005 19.7281 73.9045 19.4041 73.5765 18.9561L74.3565 18.3921C74.5405 18.7041 74.7965 18.9561 75.1245 19.1481C75.4525 19.3321 75.8405 19.4241 76.2885 19.4241C76.5045 19.4241 76.7205 19.3921 76.9365 19.3281C77.1525 19.2561 77.3445 19.1561 77.5125 19.0281C77.6885 18.8921 77.8325 18.7281 77.9445 18.5361C78.0565 18.3441 78.1125 18.1241 78.1125 17.8761C78.1125 17.5801 78.0485 17.3361 77.9205 17.1441C77.8005 16.9441 77.6365 16.7801 77.4285 16.6521C77.2285 16.5161 76.9965 16.4041 76.7325 16.3161C76.4765 16.2281 76.2085 16.1401 75.9285 16.0521C75.6565 15.9641 75.3885 15.8641 75.1245 15.7521C74.8685 15.6401 74.6365 15.5001 74.4285 15.3321C74.2285 15.1561 74.0645 14.9401 73.9365 14.6841C73.8165 14.4201 73.7565 14.0921 73.7565 13.7001C73.7565 13.2921 73.8325 12.9361 73.9845 12.6321C74.1445 12.3281 74.3525 12.0761 74.6085 11.8761C74.8725 11.6761 75.1685 11.5281 75.4965 11.4321C75.8245 11.3361 76.1605 11.2881 76.5045 11.2881C76.9845 11.2881 77.4205 11.3641 77.8125 11.5161C78.2045 11.6681 78.5685 11.9361 78.9045 12.3201L78.1485 12.9081Z" fill="#18113E" />
                    <path d="M85.0004 19.2081H86.6563C86.9683 19.2081 87.2604 19.1761 87.5324 19.1121C87.8044 19.0481 88.0404 18.9521 88.2404 18.8241C88.4404 18.6881 88.5964 18.5201 88.7084 18.3201C88.8284 18.1201 88.8884 17.8801 88.8884 17.6001C88.8884 17.0401 88.7084 16.6241 88.3484 16.3521C87.9884 16.0801 87.4604 15.9441 86.7644 15.9441H85.0004V19.2081ZM85.0004 15.2241H86.7404C86.9484 15.2241 87.1524 15.2001 87.3524 15.1521C87.5604 15.0961 87.7444 15.0081 87.9044 14.8881C88.0724 14.7681 88.2084 14.6161 88.3124 14.4321C88.4164 14.2401 88.4684 14.0081 88.4684 13.7361C88.4684 13.6481 88.4484 13.5241 88.4084 13.3641C88.3764 13.2041 88.2964 13.0441 88.1684 12.8841C88.0484 12.7241 87.8683 12.5881 87.6283 12.4761C87.3883 12.3561 87.0643 12.2961 86.6563 12.2961H85.0004V15.2241ZM84.1364 11.5041H86.6684C87.0204 11.5041 87.3604 11.5401 87.6884 11.6121C88.0164 11.6841 88.3084 11.8041 88.5644 11.9721C88.8204 12.1401 89.0244 12.3601 89.1764 12.6321C89.3284 12.9041 89.4044 13.2401 89.4044 13.6401C89.4044 14.1041 89.2724 14.5041 89.0084 14.8401C88.7524 15.1681 88.3844 15.3881 87.9044 15.5001V15.5241C88.5044 15.5721 88.9723 15.7841 89.3083 16.1601C89.6523 16.5281 89.8244 17.0241 89.8244 17.6481C89.8244 17.7841 89.7884 17.9841 89.7164 18.2481C89.6524 18.5121 89.5124 18.7761 89.2964 19.0401C89.0804 19.2961 88.7684 19.5201 88.3604 19.7121C87.9524 19.9041 87.4084 20.0001 86.7284 20.0001H84.1364V11.5041Z" fill="#18113E" />
                    <path d="M97.7669 16.8321C97.7669 17.1841 97.7229 17.5601 97.6349 17.9601C97.5549 18.3521 97.3989 18.7161 97.1669 19.0521C96.9349 19.3881 96.6149 19.6681 96.2069 19.8921C95.8069 20.1081 95.2909 20.2161 94.6589 20.2161C94.0189 20.2161 93.4989 20.1081 93.0989 19.8921C92.6989 19.6681 92.3829 19.3881 92.1509 19.0521C91.9189 18.7161 91.7589 18.3521 91.6709 17.9601C91.5909 17.5601 91.5509 17.1841 91.5509 16.8321V11.5041H92.4149V16.7361C92.4149 17.1441 92.4549 17.4961 92.5349 17.7921C92.6149 18.0801 92.7189 18.3241 92.8469 18.5241C92.9829 18.7241 93.1309 18.8841 93.2909 19.0041C93.4589 19.1161 93.6229 19.2041 93.7829 19.2681C93.9509 19.3321 94.1109 19.3761 94.2629 19.4001C94.4149 19.4161 94.5469 19.4241 94.6589 19.4241C94.7629 19.4241 94.8909 19.4161 95.0429 19.4001C95.2029 19.3761 95.3629 19.3321 95.5229 19.2681C95.6909 19.2041 95.8549 19.1161 96.0149 19.0041C96.1829 18.8841 96.3309 18.7241 96.4589 18.5241C96.5949 18.3241 96.7029 18.0801 96.7829 17.7921C96.8629 17.4961 96.9029 17.1441 96.9029 16.7361V11.5041H97.7669V16.8321Z" fill="#18113E" />
                    <path d="M102.646 20.0001H101.782V12.2961H98.9375V11.5041H105.49V12.2961H102.646V20.0001Z" fill="#18113E" />
                    <path d="M24.3517 34.4161L27.2197 27.5041H28.5397V36.0001H27.6757V28.6561H27.6517L24.5797 36.0001H24.1237L21.0517 28.6561H21.0277V36.0001H20.1637V27.5041H21.4837L24.3517 34.4161Z" fill="#18113E" />
                    <path d="M31.7035 36.0001H30.8395V27.5041H31.7035V36.0001Z" fill="#18113E" />
                    <path d="M38.0235 28.9081C37.6475 28.3561 37.0995 28.0801 36.3795 28.0801C36.1555 28.0801 35.9355 28.1121 35.7195 28.1761C35.5035 28.2321 35.3075 28.3241 35.1315 28.4521C34.9635 28.5721 34.8275 28.7321 34.7235 28.9321C34.6195 29.1241 34.5675 29.3521 34.5675 29.6161C34.5675 30.0001 34.6755 30.3001 34.8915 30.5161C35.1075 30.7241 35.3755 30.8961 35.6955 31.0321C36.0155 31.1681 36.3635 31.2921 36.7395 31.4041C37.1235 31.5081 37.4755 31.6521 37.7955 31.8361C38.1155 32.0121 38.3835 32.2481 38.5995 32.5441C38.8155 32.8401 38.9235 33.2441 38.9235 33.7561C38.9235 34.1401 38.8395 34.4881 38.6715 34.8001C38.5115 35.1041 38.2955 35.3601 38.0235 35.5681C37.7595 35.7761 37.4595 35.9361 37.1235 36.0481C36.7955 36.1601 36.4635 36.2161 36.1275 36.2161C35.6075 36.2161 35.1115 36.1201 34.6395 35.9281C34.1755 35.7281 33.7795 35.4041 33.4515 34.9561L34.2315 34.3921C34.4155 34.7041 34.6715 34.9561 34.9995 35.1481C35.3275 35.3321 35.7155 35.4241 36.1635 35.4241C36.3795 35.4241 36.5955 35.3921 36.8115 35.3281C37.0275 35.2561 37.2195 35.1561 37.3875 35.0281C37.5635 34.8921 37.7075 34.7281 37.8195 34.5361C37.9315 34.3441 37.9875 34.1241 37.9875 33.8761C37.9875 33.5801 37.9235 33.3361 37.7955 33.1441C37.6755 32.9441 37.5115 32.7801 37.3035 32.6521C37.1035 32.5161 36.8715 32.4041 36.6075 32.3161C36.3515 32.2281 36.0835 32.1401 35.8035 32.0521C35.5315 31.9641 35.2635 31.8641 34.9995 31.7521C34.7435 31.6401 34.5115 31.5001 34.3035 31.3321C34.1035 31.1561 33.9395 30.9401 33.8115 30.6841C33.6915 30.4201 33.6315 30.0921 33.6315 29.7001C33.6315 29.2921 33.7075 28.9361 33.8595 28.6321C34.0195 28.3281 34.2275 28.0761 34.4835 27.8761C34.7475 27.6761 35.0435 27.5281 35.3715 27.4321C35.6995 27.3361 36.0355 27.2881 36.3795 27.2881C36.8595 27.2881 37.2955 27.3641 37.6875 27.5161C38.0795 27.6681 38.4435 27.9361 38.7795 28.3201L38.0235 28.9081Z" fill="#18113E" />
                    <path d="M44.6915 28.9081C44.3155 28.3561 43.7675 28.0801 43.0475 28.0801C42.8235 28.0801 42.6035 28.1121 42.3875 28.1761C42.1715 28.2321 41.9755 28.3241 41.7995 28.4521C41.6315 28.5721 41.4955 28.7321 41.3915 28.9321C41.2875 29.1241 41.2355 29.3521 41.2355 29.6161C41.2355 30.0001 41.3435 30.3001 41.5595 30.5161C41.7755 30.7241 42.0435 30.8961 42.3635 31.0321C42.6835 31.1681 43.0315 31.2921 43.4075 31.4041C43.7915 31.5081 44.1435 31.6521 44.4635 31.8361C44.7835 32.0121 45.0515 32.2481 45.2675 32.5441C45.4835 32.8401 45.5915 33.2441 45.5915 33.7561C45.5915 34.1401 45.5075 34.4881 45.3395 34.8001C45.1795 35.1041 44.9635 35.3601 44.6915 35.5681C44.4275 35.7761 44.1275 35.9361 43.7915 36.0481C43.4635 36.1601 43.1315 36.2161 42.7955 36.2161C42.2755 36.2161 41.7795 36.1201 41.3075 35.9281C40.8435 35.7281 40.4475 35.4041 40.1195 34.9561L40.8995 34.3921C41.0835 34.7041 41.3395 34.9561 41.6675 35.1481C41.9955 35.3321 42.3835 35.4241 42.8315 35.4241C43.0475 35.4241 43.2635 35.3921 43.4795 35.3281C43.6955 35.2561 43.8875 35.1561 44.0555 35.0281C44.2315 34.8921 44.3755 34.7281 44.4875 34.5361C44.5995 34.3441 44.6555 34.1241 44.6555 33.8761C44.6555 33.5801 44.5915 33.3361 44.4635 33.1441C44.3435 32.9441 44.1795 32.7801 43.9715 32.6521C43.7715 32.5161 43.5395 32.4041 43.2755 32.3161C43.0195 32.2281 42.7515 32.1401 42.4715 32.0521C42.1995 31.9641 41.9315 31.8641 41.6675 31.7521C41.4115 31.6401 41.1795 31.5001 40.9715 31.3321C40.7715 31.1561 40.6075 30.9401 40.4795 30.6841C40.3595 30.4201 40.2995 30.0921 40.2995 29.7001C40.2995 29.2921 40.3755 28.9361 40.5275 28.6321C40.6875 28.3281 40.8955 28.0761 41.1515 27.8761C41.4155 27.6761 41.7115 27.5281 42.0395 27.4321C42.3675 27.3361 42.7035 27.2881 43.0475 27.2881C43.5275 27.2881 43.9635 27.3641 44.3555 27.5161C44.7475 27.6681 45.1115 27.9361 45.4475 28.3201L44.6915 28.9081Z" fill="#18113E" />
                    <path d="M48.2035 36.0001H47.3395V27.5041H48.2035V36.0001Z" fill="#18113E" />
                    <path d="M56.6475 34.7041H56.6715V27.5041H57.5355V36.0001H56.4555L51.3915 28.7281H51.3675V36.0001H50.5035V27.5041H51.5835L56.6475 34.7041Z" fill="#18113E" />
                    <path d="M66.1194 29.0641C65.8314 28.7601 65.4874 28.5201 65.0874 28.3441C64.6874 28.1681 64.2234 28.0801 63.6954 28.0801C63.1514 28.0801 62.6634 28.1801 62.2314 28.3801C61.7994 28.5801 61.4314 28.8481 61.1274 29.1841C60.8314 29.5201 60.6034 29.9121 60.4434 30.3601C60.2834 30.8001 60.2034 31.2641 60.2034 31.7521C60.2034 32.2401 60.2834 32.7081 60.4434 33.1561C60.6034 33.5961 60.8314 33.9841 61.1274 34.3201C61.4314 34.6561 61.7994 34.9241 62.2314 35.1241C62.6634 35.3241 63.1514 35.4241 63.6954 35.4241C64.1354 35.4241 64.5554 35.3721 64.9554 35.2681C65.3634 35.1561 65.7274 35.0081 66.0474 34.8241V31.9801H64.0194V31.1881H66.9114V35.3521C66.4314 35.6321 65.9034 35.8481 65.3274 36.0001C64.7594 36.1441 64.2154 36.2161 63.6954 36.2161C63.0634 36.2161 62.4754 36.1041 61.9314 35.8801C61.3954 35.6561 60.9274 35.3481 60.5274 34.9561C60.1354 34.5561 59.8274 34.0841 59.6034 33.5401C59.3794 32.9961 59.2674 32.4001 59.2674 31.7521C59.2674 31.1041 59.3794 30.5081 59.6034 29.9641C59.8274 29.4201 60.1354 28.9521 60.5274 28.5601C60.9274 28.1601 61.3954 27.8481 61.9314 27.6241C62.4754 27.4001 63.0634 27.2881 63.6954 27.2881C64.3754 27.2881 64.9594 27.3841 65.4474 27.5761C65.9434 27.7601 66.3834 28.0441 66.7674 28.4281L66.1194 29.0641Z" fill="#18113E" />
                    <path d="M73.3871 36.0001H72.5231V27.5041H73.3871V36.0001Z" fill="#18113E" />
                    <path d="M81.8311 34.7041H81.8551V27.5041H82.7191V36.0001H81.6391L76.5751 28.7281H76.5511V36.0001H75.6871V27.5041H76.7671L81.8311 34.7041Z" fill="#18113E" />
                    <path d="M92.3868 28.9081C92.0108 28.3561 91.4628 28.0801 90.7428 28.0801C90.5188 28.0801 90.2988 28.1121 90.0828 28.1761C89.8668 28.2321 89.6708 28.3241 89.4948 28.4521C89.3268 28.5721 89.1908 28.7321 89.0868 28.9321C88.9828 29.1241 88.9308 29.3521 88.9308 29.6161C88.9308 30.0001 89.0388 30.3001 89.2548 30.5161C89.4708 30.7241 89.7388 30.8961 90.0588 31.0321C90.3788 31.1681 90.7268 31.2921 91.1028 31.4041C91.4868 31.5081 91.8388 31.6521 92.1588 31.8361C92.4788 32.0121 92.7468 32.2481 92.9628 32.5441C93.1788 32.8401 93.2868 33.2441 93.2868 33.7561C93.2868 34.1401 93.2028 34.4881 93.0348 34.8001C92.8748 35.1041 92.6588 35.3601 92.3868 35.5681C92.1228 35.7761 91.8228 35.9361 91.4868 36.0481C91.1588 36.1601 90.8268 36.2161 90.4908 36.2161C89.9708 36.2161 89.4748 36.1201 89.0028 35.9281C88.5388 35.7281 88.1428 35.4041 87.8148 34.9561L88.5948 34.3921C88.7788 34.7041 89.0348 34.9561 89.3628 35.1481C89.6908 35.3321 90.0788 35.4241 90.5268 35.4241C90.7428 35.4241 90.9588 35.3921 91.1748 35.3281C91.3908 35.2561 91.5828 35.1561 91.7508 35.0281C91.9268 34.8921 92.0708 34.7281 92.1828 34.5361C92.2948 34.3441 92.3508 34.1241 92.3508 33.8761C92.3508 33.5801 92.2868 33.3361 92.1588 33.1441C92.0388 32.9441 91.8748 32.7801 91.6668 32.6521C91.4668 32.5161 91.2348 32.4041 90.9708 32.3161C90.7148 32.2281 90.4468 32.1401 90.1668 32.0521C89.8948 31.9641 89.6268 31.8641 89.3628 31.7521C89.1068 31.6401 88.8748 31.5001 88.6668 31.3321C88.4668 31.1561 88.3028 30.9401 88.1748 30.6841C88.0548 30.4201 87.9948 30.0921 87.9948 29.7001C87.9948 29.2921 88.0708 28.9361 88.2228 28.6321C88.3828 28.3281 88.5908 28.0761 88.8468 27.8761C89.1108 27.6761 89.4068 27.5281 89.7348 27.4321C90.0628 27.3361 90.3988 27.2881 90.7428 27.2881C91.2228 27.2881 91.6588 27.3641 92.0508 27.5161C92.4428 27.6681 92.8068 27.9361 93.1428 28.3201L92.3868 28.9081Z" fill="#18113E" />
                    <path d="M96.1508 32.9761H99.8348L97.9988 28.5121L96.1508 32.9761ZM94.8908 36.0001H93.8828L97.5788 27.5041H98.4188L102.115 36.0001H101.107L100.171 33.7681H95.8268L94.8908 36.0001Z" fill="#18113E" />
                    <path d="M104.125 31.2601H105.517C105.901 31.2601 106.225 31.2201 106.489 31.1401C106.761 31.0601 106.977 30.9561 107.137 30.8281C107.297 30.6921 107.413 30.5361 107.485 30.3601C107.557 30.1761 107.593 29.9801 107.593 29.7721C107.593 29.5721 107.557 29.3841 107.485 29.2081C107.413 29.0241 107.297 28.8641 107.137 28.7281C106.977 28.5921 106.761 28.4881 106.489 28.4161C106.225 28.3361 105.901 28.2961 105.517 28.2961H104.125V31.2601ZM103.261 27.5041H105.733C106.597 27.5041 107.277 27.6921 107.773 28.0681C108.277 28.4441 108.529 29.0121 108.529 29.7721C108.529 30.5321 108.277 31.1041 107.773 31.4881C107.277 31.8641 106.597 32.0521 105.733 32.0521H104.125V36.0001H103.261V27.5041Z" fill="#18113E" />
                    <path d="M185.268 179.976H188.952L187.116 175.512L185.268 179.976ZM184.008 183H183L186.696 174.504H187.536L191.232 183H190.224L189.288 180.768H184.944L184.008 183Z" fill="#18113E" />
                    <path d="M196.399 175.908C196.023 175.356 195.475 175.08 194.755 175.08C194.531 175.08 194.311 175.112 194.095 175.176C193.879 175.232 193.683 175.324 193.507 175.452C193.339 175.572 193.203 175.732 193.099 175.932C192.995 176.124 192.943 176.352 192.943 176.616C192.943 177 193.051 177.3 193.267 177.516C193.483 177.724 193.751 177.896 194.071 178.032C194.391 178.168 194.739 178.292 195.115 178.404C195.499 178.508 195.851 178.652 196.171 178.836C196.491 179.012 196.759 179.248 196.975 179.544C197.191 179.84 197.299 180.244 197.299 180.756C197.299 181.14 197.215 181.488 197.047 181.8C196.887 182.104 196.671 182.36 196.399 182.568C196.135 182.776 195.835 182.936 195.499 183.048C195.171 183.16 194.839 183.216 194.503 183.216C193.983 183.216 193.487 183.12 193.015 182.928C192.551 182.728 192.155 182.404 191.827 181.956L192.607 181.392C192.791 181.704 193.047 181.956 193.375 182.148C193.703 182.332 194.091 182.424 194.539 182.424C194.755 182.424 194.971 182.392 195.187 182.328C195.403 182.256 195.595 182.156 195.763 182.028C195.939 181.892 196.083 181.728 196.195 181.536C196.307 181.344 196.363 181.124 196.363 180.876C196.363 180.58 196.299 180.336 196.171 180.144C196.051 179.944 195.887 179.78 195.679 179.652C195.479 179.516 195.247 179.404 194.983 179.316C194.727 179.228 194.459 179.14 194.179 179.052C193.907 178.964 193.639 178.864 193.375 178.752C193.119 178.64 192.887 178.5 192.679 178.332C192.479 178.156 192.315 177.94 192.187 177.684C192.067 177.42 192.007 177.092 192.007 176.7C192.007 176.292 192.083 175.936 192.235 175.632C192.395 175.328 192.603 175.076 192.859 174.876C193.123 174.676 193.419 174.528 193.747 174.432C194.075 174.336 194.411 174.288 194.755 174.288C195.235 174.288 195.671 174.364 196.063 174.516C196.455 174.668 196.819 174.936 197.155 175.32L196.399 175.908Z" fill="#18113E" />
                    <path d="M203.067 175.908C202.691 175.356 202.143 175.08 201.423 175.08C201.199 175.08 200.979 175.112 200.763 175.176C200.547 175.232 200.351 175.324 200.175 175.452C200.007 175.572 199.871 175.732 199.767 175.932C199.663 176.124 199.611 176.352 199.611 176.616C199.611 177 199.719 177.3 199.935 177.516C200.151 177.724 200.419 177.896 200.739 178.032C201.059 178.168 201.407 178.292 201.783 178.404C202.167 178.508 202.519 178.652 202.839 178.836C203.159 179.012 203.427 179.248 203.643 179.544C203.859 179.84 203.967 180.244 203.967 180.756C203.967 181.14 203.883 181.488 203.715 181.8C203.555 182.104 203.339 182.36 203.067 182.568C202.803 182.776 202.503 182.936 202.167 183.048C201.839 183.16 201.507 183.216 201.171 183.216C200.651 183.216 200.155 183.12 199.683 182.928C199.219 182.728 198.823 182.404 198.495 181.956L199.275 181.392C199.459 181.704 199.715 181.956 200.043 182.148C200.371 182.332 200.759 182.424 201.207 182.424C201.423 182.424 201.639 182.392 201.855 182.328C202.071 182.256 202.263 182.156 202.431 182.028C202.607 181.892 202.751 181.728 202.863 181.536C202.975 181.344 203.031 181.124 203.031 180.876C203.031 180.58 202.967 180.336 202.839 180.144C202.719 179.944 202.555 179.78 202.347 179.652C202.147 179.516 201.915 179.404 201.651 179.316C201.395 179.228 201.127 179.14 200.847 179.052C200.575 178.964 200.307 178.864 200.043 178.752C199.787 178.64 199.555 178.5 199.347 178.332C199.147 178.156 198.983 177.94 198.855 177.684C198.735 177.42 198.675 177.092 198.675 176.7C198.675 176.292 198.751 175.936 198.903 175.632C199.063 175.328 199.271 175.076 199.527 174.876C199.791 174.676 200.087 174.528 200.415 174.432C200.743 174.336 201.079 174.288 201.423 174.288C201.903 174.288 202.339 174.364 202.731 174.516C203.123 174.668 203.487 174.936 203.823 175.32L203.067 175.908Z" fill="#18113E" />
                    <path d="M206.578 182.208H211.126V183H205.714V174.504H210.982V175.296H206.578V178.188H210.694V178.98H206.578V182.208Z" fill="#18113E" />
                    <path d="M215.552 183H214.688V175.296H211.844V174.504H218.396V175.296H215.552V183Z" fill="#18113E" />
                    <path d="M223.738 175.908C223.362 175.356 222.814 175.08 222.094 175.08C221.87 175.08 221.65 175.112 221.434 175.176C221.218 175.232 221.022 175.324 220.846 175.452C220.678 175.572 220.542 175.732 220.438 175.932C220.334 176.124 220.282 176.352 220.282 176.616C220.282 177 220.39 177.3 220.606 177.516C220.822 177.724 221.09 177.896 221.41 178.032C221.73 178.168 222.078 178.292 222.454 178.404C222.838 178.508 223.19 178.652 223.51 178.836C223.83 179.012 224.098 179.248 224.314 179.544C224.53 179.84 224.638 180.244 224.638 180.756C224.638 181.14 224.554 181.488 224.386 181.8C224.226 182.104 224.01 182.36 223.738 182.568C223.474 182.776 223.174 182.936 222.838 183.048C222.51 183.16 222.178 183.216 221.842 183.216C221.322 183.216 220.826 183.12 220.354 182.928C219.89 182.728 219.494 182.404 219.166 181.956L219.946 181.392C220.13 181.704 220.386 181.956 220.714 182.148C221.042 182.332 221.43 182.424 221.878 182.424C222.094 182.424 222.31 182.392 222.526 182.328C222.742 182.256 222.934 182.156 223.102 182.028C223.278 181.892 223.422 181.728 223.534 181.536C223.646 181.344 223.702 181.124 223.702 180.876C223.702 180.58 223.638 180.336 223.51 180.144C223.39 179.944 223.226 179.78 223.018 179.652C222.818 179.516 222.586 179.404 222.322 179.316C222.066 179.228 221.798 179.14 221.518 179.052C221.246 178.964 220.978 178.864 220.714 178.752C220.458 178.64 220.226 178.5 220.018 178.332C219.818 178.156 219.654 177.94 219.526 177.684C219.406 177.42 219.346 177.092 219.346 176.7C219.346 176.292 219.422 175.936 219.574 175.632C219.734 175.328 219.942 175.076 220.198 174.876C220.462 174.676 220.758 174.528 221.086 174.432C221.414 174.336 221.75 174.288 222.094 174.288C222.574 174.288 223.01 174.364 223.402 174.516C223.794 174.668 224.158 174.936 224.494 175.32L223.738 175.908Z" fill="#18113E" />
                    <path d="M231.574 181.776H231.598L233.734 174.504H234.766L236.902 181.776H236.926L238.942 174.504H239.902L237.394 183H236.386L234.25 175.728H234.226L232.09 183H231.082L228.574 174.504H229.534L231.574 181.776Z" fill="#18113E" />
                    <path d="M241.922 183H241.058V174.504H241.922V183Z" fill="#18113E" />
                    <path d="M246.946 183H246.082V175.296H243.238V174.504H249.79V175.296H246.946V183Z" fill="#18113E" />
                    <path d="M251.113 174.504H251.977V178.188H256.609V174.504H257.473V183H256.609V178.98H251.977V183H251.113V174.504Z" fill="#18113E" />
                    <path d="M185.016 198.208H189.564V199H184.152V190.504H189.42V191.296H185.016V194.188H189.132V194.98H185.016V198.208Z" fill="#18113E" />
                    <path d="M192.129 194.26H193.665C194.049 194.26 194.373 194.22 194.637 194.14C194.909 194.06 195.125 193.956 195.285 193.828C195.445 193.692 195.561 193.536 195.633 193.36C195.705 193.176 195.741 192.98 195.741 192.772C195.741 192.572 195.705 192.384 195.633 192.208C195.561 192.024 195.445 191.864 195.285 191.728C195.125 191.592 194.909 191.488 194.637 191.416C194.373 191.336 194.049 191.296 193.665 191.296H192.129V194.26ZM192.129 199H191.265V190.504H193.881C194.745 190.504 195.425 190.692 195.921 191.068C196.425 191.444 196.677 192.012 196.677 192.772C196.677 193.364 196.489 193.864 196.113 194.272C195.745 194.672 195.209 194.904 194.505 194.968L196.953 199H195.897L193.569 195.052H192.129V199Z" fill="#18113E" />
                    <path d="M199.243 194.26H200.779C201.163 194.26 201.487 194.22 201.751 194.14C202.023 194.06 202.239 193.956 202.399 193.828C202.559 193.692 202.675 193.536 202.747 193.36C202.819 193.176 202.855 192.98 202.855 192.772C202.855 192.572 202.819 192.384 202.747 192.208C202.675 192.024 202.559 191.864 202.399 191.728C202.239 191.592 202.023 191.488 201.751 191.416C201.487 191.336 201.163 191.296 200.779 191.296H199.243V194.26ZM199.243 199H198.379V190.504H200.995C201.859 190.504 202.539 190.692 203.035 191.068C203.539 191.444 203.791 192.012 203.791 192.772C203.791 193.364 203.603 193.864 203.227 194.272C202.859 194.672 202.323 194.904 201.619 194.968L204.067 199H203.011L200.683 195.052H199.243V199Z" fill="#18113E" />
                    <path d="M212.836 194.752C212.836 194.264 212.756 193.8 212.596 193.36C212.436 192.912 212.204 192.52 211.9 192.184C211.604 191.848 211.24 191.58 210.808 191.38C210.376 191.18 209.888 191.08 209.344 191.08C208.8 191.08 208.312 191.18 207.88 191.38C207.448 191.58 207.08 191.848 206.776 192.184C206.48 192.52 206.252 192.912 206.092 193.36C205.932 193.8 205.852 194.264 205.852 194.752C205.852 195.24 205.932 195.708 206.092 196.156C206.252 196.596 206.48 196.984 206.776 197.32C207.08 197.656 207.448 197.924 207.88 198.124C208.312 198.324 208.8 198.424 209.344 198.424C209.888 198.424 210.376 198.324 210.808 198.124C211.24 197.924 211.604 197.656 211.9 197.32C212.204 196.984 212.436 196.596 212.596 196.156C212.756 195.708 212.836 195.24 212.836 194.752ZM213.772 194.752C213.772 195.4 213.66 195.996 213.436 196.54C213.212 197.084 212.9 197.556 212.5 197.956C212.108 198.348 211.64 198.656 211.096 198.88C210.56 199.104 209.976 199.216 209.344 199.216C208.712 199.216 208.124 199.104 207.58 198.88C207.044 198.656 206.576 198.348 206.176 197.956C205.784 197.556 205.476 197.084 205.252 196.54C205.028 195.996 204.916 195.4 204.916 194.752C204.916 194.104 205.028 193.508 205.252 192.964C205.476 192.42 205.784 191.952 206.176 191.56C206.576 191.16 207.044 190.848 207.58 190.624C208.124 190.4 208.712 190.288 209.344 190.288C209.976 190.288 210.56 190.4 211.096 190.624C211.64 190.848 212.108 191.16 212.5 191.56C212.9 191.952 213.212 192.42 213.436 192.964C213.66 193.508 213.772 194.104 213.772 194.752Z" fill="#18113E" />
                    <path d="M216.364 194.26H217.9C218.284 194.26 218.608 194.22 218.872 194.14C219.144 194.06 219.36 193.956 219.52 193.828C219.68 193.692 219.796 193.536 219.868 193.36C219.94 193.176 219.976 192.98 219.976 192.772C219.976 192.572 219.94 192.384 219.868 192.208C219.796 192.024 219.68 191.864 219.52 191.728C219.36 191.592 219.144 191.488 218.872 191.416C218.608 191.336 218.284 191.296 217.9 191.296H216.364V194.26ZM216.364 199H215.5V190.504H218.116C218.98 190.504 219.66 190.692 220.156 191.068C220.66 191.444 220.912 192.012 220.912 192.772C220.912 193.364 220.724 193.864 220.348 194.272C219.98 194.672 219.444 194.904 218.74 194.968L221.188 199H220.132L217.804 195.052H216.364V199Z" fill="#18113E" />
                    <path d="M226.633 191.908C226.257 191.356 225.709 191.08 224.989 191.08C224.765 191.08 224.545 191.112 224.329 191.176C224.113 191.232 223.917 191.324 223.741 191.452C223.573 191.572 223.437 191.732 223.333 191.932C223.229 192.124 223.177 192.352 223.177 192.616C223.177 193 223.285 193.3 223.501 193.516C223.717 193.724 223.985 193.896 224.305 194.032C224.625 194.168 224.973 194.292 225.349 194.404C225.733 194.508 226.085 194.652 226.405 194.836C226.725 195.012 226.993 195.248 227.209 195.544C227.425 195.84 227.533 196.244 227.533 196.756C227.533 197.14 227.449 197.488 227.281 197.8C227.121 198.104 226.905 198.36 226.633 198.568C226.369 198.776 226.069 198.936 225.733 199.048C225.405 199.16 225.073 199.216 224.737 199.216C224.217 199.216 223.721 199.12 223.249 198.928C222.785 198.728 222.389 198.404 222.061 197.956L222.841 197.392C223.025 197.704 223.281 197.956 223.609 198.148C223.937 198.332 224.325 198.424 224.773 198.424C224.989 198.424 225.205 198.392 225.421 198.328C225.637 198.256 225.829 198.156 225.997 198.028C226.173 197.892 226.317 197.728 226.429 197.536C226.541 197.344 226.597 197.124 226.597 196.876C226.597 196.58 226.533 196.336 226.405 196.144C226.285 195.944 226.121 195.78 225.913 195.652C225.713 195.516 225.481 195.404 225.217 195.316C224.961 195.228 224.693 195.14 224.413 195.052C224.141 194.964 223.873 194.864 223.609 194.752C223.353 194.64 223.121 194.5 222.913 194.332C222.713 194.156 222.549 193.94 222.421 193.684C222.301 193.42 222.241 193.092 222.241 192.7C222.241 192.292 222.317 191.936 222.469 191.632C222.629 191.328 222.837 191.076 223.093 190.876C223.357 190.676 223.653 190.528 223.981 190.432C224.309 190.336 224.645 190.288 224.989 190.288C225.469 190.288 225.905 190.364 226.297 190.516C226.689 190.668 227.053 190.936 227.389 191.32L226.633 191.908Z" fill="#18113E" />
                    <path d="M208.016 115.208H211.976V116H207.152V107.504H208.016V115.208Z" fill="#18113E" />
                    <path d="M214.016 116H213.152V107.504H214.016V116Z" fill="#18113E" />
                    <path d="M222.46 114.704H222.484V107.504H223.348V116H222.268L217.204 108.728H217.18V116H216.316V107.504H217.396L222.46 114.704Z" fill="#18113E" />
                    <path d="M226.52 111.188H226.64L230.636 107.504H231.872L227.588 111.356L232.112 116H230.84L226.664 111.62H226.52V116H225.656V107.504H226.52V111.188Z" fill="#18113E" />
                    <path d="M234.078 115.208H238.626V116H233.214V107.504H238.482V108.296H234.078V111.188H238.194V111.98H234.078V115.208Z" fill="#18113E" />
                    <path d="M241.072 115.208H242.752C243.4 115.208 243.96 115.112 244.432 114.92C244.912 114.72 245.308 114.46 245.62 114.14C245.94 113.812 246.176 113.444 246.328 113.036C246.48 112.62 246.556 112.192 246.556 111.752C246.556 111.312 246.48 110.888 246.328 110.48C246.176 110.064 245.94 109.696 245.62 109.376C245.308 109.048 244.912 108.788 244.432 108.596C243.96 108.396 243.4 108.296 242.752 108.296H241.072V115.208ZM240.208 107.504H243.028C243.556 107.504 244.084 107.592 244.612 107.768C245.148 107.944 245.628 108.212 246.052 108.572C246.484 108.924 246.832 109.368 247.096 109.904C247.36 110.432 247.492 111.048 247.492 111.752C247.492 112.464 247.36 113.084 247.096 113.612C246.832 114.14 246.484 114.584 246.052 114.944C245.628 115.296 245.148 115.56 244.612 115.736C244.084 115.912 243.556 116 243.028 116H240.208V107.504Z" fill="#18113E" />
                    <path d="M208.268 128.976H211.952L210.116 124.512L208.268 128.976ZM207.008 132H206L209.696 123.504H210.536L214.232 132H213.224L212.288 129.768H207.944L207.008 132Z" fill="#18113E" />
                    <path d="M219.399 124.908C219.023 124.356 218.475 124.08 217.755 124.08C217.531 124.08 217.311 124.112 217.095 124.176C216.879 124.232 216.683 124.324 216.507 124.452C216.339 124.572 216.203 124.732 216.099 124.932C215.995 125.124 215.943 125.352 215.943 125.616C215.943 126 216.051 126.3 216.267 126.516C216.483 126.724 216.751 126.896 217.071 127.032C217.391 127.168 217.739 127.292 218.115 127.404C218.499 127.508 218.851 127.652 219.171 127.836C219.491 128.012 219.759 128.248 219.975 128.544C220.191 128.84 220.299 129.244 220.299 129.756C220.299 130.14 220.215 130.488 220.047 130.8C219.887 131.104 219.671 131.36 219.399 131.568C219.135 131.776 218.835 131.936 218.499 132.048C218.171 132.16 217.839 132.216 217.503 132.216C216.983 132.216 216.487 132.12 216.015 131.928C215.551 131.728 215.155 131.404 214.827 130.956L215.607 130.392C215.791 130.704 216.047 130.956 216.375 131.148C216.703 131.332 217.091 131.424 217.539 131.424C217.755 131.424 217.971 131.392 218.187 131.328C218.403 131.256 218.595 131.156 218.763 131.028C218.939 130.892 219.083 130.728 219.195 130.536C219.307 130.344 219.363 130.124 219.363 129.876C219.363 129.58 219.299 129.336 219.171 129.144C219.051 128.944 218.887 128.78 218.679 128.652C218.479 128.516 218.247 128.404 217.983 128.316C217.727 128.228 217.459 128.14 217.179 128.052C216.907 127.964 216.639 127.864 216.375 127.752C216.119 127.64 215.887 127.5 215.679 127.332C215.479 127.156 215.315 126.94 215.187 126.684C215.067 126.42 215.007 126.092 215.007 125.7C215.007 125.292 215.083 124.936 215.235 124.632C215.395 124.328 215.603 124.076 215.859 123.876C216.123 123.676 216.419 123.528 216.747 123.432C217.075 123.336 217.411 123.288 217.755 123.288C218.235 123.288 218.671 123.364 219.063 123.516C219.455 123.668 219.819 123.936 220.155 124.32L219.399 124.908Z" fill="#18113E" />
                    <path d="M226.067 124.908C225.691 124.356 225.143 124.08 224.423 124.08C224.199 124.08 223.979 124.112 223.763 124.176C223.547 124.232 223.351 124.324 223.175 124.452C223.007 124.572 222.871 124.732 222.767 124.932C222.663 125.124 222.611 125.352 222.611 125.616C222.611 126 222.719 126.3 222.935 126.516C223.151 126.724 223.419 126.896 223.739 127.032C224.059 127.168 224.407 127.292 224.783 127.404C225.167 127.508 225.519 127.652 225.839 127.836C226.159 128.012 226.427 128.248 226.643 128.544C226.859 128.84 226.967 129.244 226.967 129.756C226.967 130.14 226.883 130.488 226.715 130.8C226.555 131.104 226.339 131.36 226.067 131.568C225.803 131.776 225.503 131.936 225.167 132.048C224.839 132.16 224.507 132.216 224.171 132.216C223.651 132.216 223.155 132.12 222.683 131.928C222.219 131.728 221.823 131.404 221.495 130.956L222.275 130.392C222.459 130.704 222.715 130.956 223.043 131.148C223.371 131.332 223.759 131.424 224.207 131.424C224.423 131.424 224.639 131.392 224.855 131.328C225.071 131.256 225.263 131.156 225.431 131.028C225.607 130.892 225.751 130.728 225.863 130.536C225.975 130.344 226.031 130.124 226.031 129.876C226.031 129.58 225.967 129.336 225.839 129.144C225.719 128.944 225.555 128.78 225.347 128.652C225.147 128.516 224.915 128.404 224.651 128.316C224.395 128.228 224.127 128.14 223.847 128.052C223.575 127.964 223.307 127.864 223.043 127.752C222.787 127.64 222.555 127.5 222.347 127.332C222.147 127.156 221.983 126.94 221.855 126.684C221.735 126.42 221.675 126.092 221.675 125.7C221.675 125.292 221.751 124.936 221.903 124.632C222.063 124.328 222.271 124.076 222.527 123.876C222.791 123.676 223.087 123.528 223.415 123.432C223.743 123.336 224.079 123.288 224.423 123.288C224.903 123.288 225.339 123.364 225.731 123.516C226.123 123.668 226.487 123.936 226.823 124.32L226.067 124.908Z" fill="#18113E" />
                    <path d="M229.578 131.208H234.126V132H228.714V123.504H233.982V124.296H229.578V127.188H233.694V127.98H229.578V131.208Z" fill="#18113E" />
                    <path d="M238.552 132H237.688V124.296H234.844V123.504H241.396V124.296H238.552V132Z" fill="#18113E" />
                    <path d="M246.738 124.908C246.362 124.356 245.814 124.08 245.094 124.08C244.87 124.08 244.65 124.112 244.434 124.176C244.218 124.232 244.022 124.324 243.846 124.452C243.678 124.572 243.542 124.732 243.438 124.932C243.334 125.124 243.282 125.352 243.282 125.616C243.282 126 243.39 126.3 243.606 126.516C243.822 126.724 244.09 126.896 244.41 127.032C244.73 127.168 245.078 127.292 245.454 127.404C245.838 127.508 246.19 127.652 246.51 127.836C246.83 128.012 247.098 128.248 247.314 128.544C247.53 128.84 247.638 129.244 247.638 129.756C247.638 130.14 247.554 130.488 247.386 130.8C247.226 131.104 247.01 131.36 246.738 131.568C246.474 131.776 246.174 131.936 245.838 132.048C245.51 132.16 245.178 132.216 244.842 132.216C244.322 132.216 243.826 132.12 243.354 131.928C242.89 131.728 242.494 131.404 242.166 130.956L242.946 130.392C243.13 130.704 243.386 130.956 243.714 131.148C244.042 131.332 244.43 131.424 244.878 131.424C245.094 131.424 245.31 131.392 245.526 131.328C245.742 131.256 245.934 131.156 246.102 131.028C246.278 130.892 246.422 130.728 246.534 130.536C246.646 130.344 246.702 130.124 246.702 129.876C246.702 129.58 246.638 129.336 246.51 129.144C246.39 128.944 246.226 128.78 246.018 128.652C245.818 128.516 245.586 128.404 245.322 128.316C245.066 128.228 244.798 128.14 244.518 128.052C244.246 127.964 243.978 127.864 243.714 127.752C243.458 127.64 243.226 127.5 243.018 127.332C242.818 127.156 242.654 126.94 242.526 126.684C242.406 126.42 242.346 126.092 242.346 125.7C242.346 125.292 242.422 124.936 242.574 124.632C242.734 124.328 242.942 124.076 243.198 123.876C243.462 123.676 243.758 123.528 244.086 123.432C244.414 123.336 244.75 123.288 245.094 123.288C245.574 123.288 246.01 123.364 246.402 123.516C246.794 123.668 247.158 123.936 247.494 124.32L246.738 124.908Z" fill="#18113E" />
                </g>
                <text id="Figures - Linked Assets" fill="black"   font-family="Avenir" font-size="29" letter-spacing="0em"><tspan x="136.543" y="129.693">{totalRecords - in_gis_but_missing_in_sap}</tspan></text>
                <g id="Vector_2">
                    <path d="M78.941 129.4C77.469 130.168 75.933 130.723 74.333 131.064C72.733 131.405 71.101 131.576 69.437 131.576C67.6663 131.576 66.0343 131.299 64.541 130.744C63.0477 130.189 61.757 129.4 60.669 128.376C59.581 127.331 58.7277 126.072 58.109 124.6C57.5117 123.128 57.213 121.485 57.213 119.672C57.213 117.859 57.5117 116.216 58.109 114.744C58.7277 113.272 59.581 112.024 60.669 111C61.757 109.955 63.0477 109.155 64.541 108.6C66.0343 108.045 67.6663 107.768 69.437 107.768C71.2503 107.768 72.9143 107.992 74.429 108.44C75.965 108.867 77.309 109.613 78.461 110.68L74.941 114.52C74.2583 113.816 73.4903 113.283 72.637 112.92C71.7837 112.557 70.717 112.376 69.437 112.376C68.3703 112.376 67.3997 112.557 66.525 112.92C65.6717 113.283 64.9357 113.795 64.317 114.456C63.6983 115.096 63.2183 115.864 62.877 116.76C62.557 117.656 62.397 118.627 62.397 119.672C62.397 120.739 62.557 121.72 62.877 122.616C63.2183 123.491 63.6983 124.259 64.317 124.92C64.9357 125.56 65.6717 126.061 66.525 126.424C67.3997 126.787 68.3703 126.968 69.437 126.968C70.525 126.968 71.4637 126.851 72.253 126.616C73.0423 126.36 73.6717 126.104 74.141 125.848V122.168H70.077V117.56H78.941V129.4Z" fill="#18113E" />
                    <path d="M83.2713 108.344H88.2633V131H83.2713V108.344Z" fill="#18113E" />
                    <path d="M104.004 113.88C103.599 113.368 103.044 112.995 102.34 112.76C101.657 112.504 101.007 112.376 100.388 112.376C100.025 112.376 99.652 112.419 99.268 112.504C98.884 112.589 98.5213 112.728 98.18 112.92C97.8387 113.091 97.5613 113.325 97.348 113.624C97.1347 113.923 97.028 114.285 97.028 114.712C97.028 115.395 97.284 115.917 97.796 116.28C98.308 116.643 98.948 116.952 99.716 117.208C100.505 117.464 101.348 117.72 102.244 117.976C103.14 118.232 103.972 118.595 104.74 119.064C105.529 119.533 106.18 120.163 106.692 120.952C107.204 121.741 107.46 122.797 107.46 124.12C107.46 125.379 107.225 126.477 106.756 127.416C106.287 128.355 105.647 129.133 104.836 129.752C104.047 130.371 103.119 130.829 102.052 131.128C101.007 131.427 99.8973 131.576 98.724 131.576C97.252 131.576 95.8867 131.352 94.628 130.904C93.3693 130.456 92.196 129.731 91.108 128.728L94.66 124.824C95.172 125.507 95.812 126.04 96.58 126.424C97.3693 126.787 98.18 126.968 99.012 126.968C99.4173 126.968 99.8227 126.925 100.228 126.84C100.655 126.733 101.028 126.584 101.348 126.392C101.689 126.2 101.956 125.955 102.148 125.656C102.361 125.357 102.468 125.005 102.468 124.6C102.468 123.917 102.201 123.384 101.668 123C101.156 122.595 100.505 122.253 99.716 121.976C98.9267 121.699 98.0733 121.421 97.156 121.144C96.2387 120.867 95.3853 120.493 94.596 120.024C93.8067 119.555 93.1453 118.936 92.612 118.168C92.1 117.4 91.844 116.387 91.844 115.128C91.844 113.912 92.0787 112.845 92.548 111.928C93.0387 111.011 93.6787 110.243 94.468 109.624C95.2787 109.005 96.2067 108.547 97.252 108.248C98.2973 107.928 99.3747 107.768 100.484 107.768C101.764 107.768 103.001 107.949 104.196 108.312C105.391 108.675 106.468 109.283 107.428 110.136L104.004 113.88Z" fill="#18113E" />
                    <path d="M279.879 113.88C279.474 113.368 278.919 112.995 278.215 112.76C277.532 112.504 276.882 112.376 276.263 112.376C275.9 112.376 275.527 112.419 275.143 112.504C274.759 112.589 274.396 112.728 274.055 112.92C273.714 113.091 273.436 113.325 273.223 113.624C273.01 113.923 272.903 114.285 272.903 114.712C272.903 115.395 273.159 115.917 273.671 116.28C274.183 116.643 274.823 116.952 275.591 117.208C276.38 117.464 277.223 117.72 278.119 117.976C279.015 118.232 279.847 118.595 280.615 119.064C281.404 119.533 282.055 120.163 282.567 120.952C283.079 121.741 283.335 122.797 283.335 124.12C283.335 125.379 283.1 126.477 282.631 127.416C282.162 128.355 281.522 129.133 280.711 129.752C279.922 130.371 278.994 130.829 277.927 131.128C276.882 131.427 275.772 131.576 274.599 131.576C273.127 131.576 271.762 131.352 270.503 130.904C269.244 130.456 268.071 129.731 266.983 128.728L270.535 124.824C271.047 125.507 271.687 126.04 272.455 126.424C273.244 126.787 274.055 126.968 274.887 126.968C275.292 126.968 275.698 126.925 276.103 126.84C276.53 126.733 276.903 126.584 277.223 126.392C277.564 126.2 277.831 125.955 278.023 125.656C278.236 125.357 278.343 125.005 278.343 124.6C278.343 123.917 278.076 123.384 277.543 123C277.031 122.595 276.38 122.253 275.591 121.976C274.802 121.699 273.948 121.421 273.031 121.144C272.114 120.867 271.26 120.493 270.471 120.024C269.682 119.555 269.02 118.936 268.487 118.168C267.975 117.4 267.719 116.387 267.719 115.128C267.719 113.912 267.954 112.845 268.423 111.928C268.914 111.011 269.554 110.243 270.343 109.624C271.154 109.005 272.082 108.547 273.127 108.248C274.172 107.928 275.25 107.768 276.359 107.768C277.639 107.768 278.876 107.949 280.071 108.312C281.266 108.675 282.343 109.283 283.303 110.136L279.879 113.88Z" fill="#18113E" />
                    <path d="M294.51 108.344H298.638L308.494 131H302.862L300.91 126.2H292.11L290.222 131H284.718L294.51 108.344ZM296.43 114.936L293.678 121.976H299.214L296.43 114.936Z" fill="#18113E" />
                    <path d="M310.773 108.344H319.189C320.362 108.344 321.471 108.451 322.517 108.664C323.562 108.877 324.469 109.251 325.237 109.784C326.026 110.296 326.645 111 327.093 111.896C327.562 112.771 327.797 113.88 327.797 115.224C327.797 116.547 327.583 117.656 327.157 118.552C326.73 119.448 326.143 120.163 325.397 120.696C324.65 121.229 323.765 121.613 322.741 121.848C321.717 122.061 320.607 122.168 319.413 122.168H315.765V131H310.773V108.344ZM315.765 117.944H319.093C319.541 117.944 319.967 117.901 320.373 117.816C320.799 117.731 321.173 117.592 321.493 117.4C321.834 117.187 322.101 116.909 322.293 116.568C322.506 116.205 322.613 115.757 322.613 115.224C322.613 114.648 322.474 114.189 322.197 113.848C321.941 113.485 321.599 113.208 321.173 113.016C320.767 112.824 320.309 112.707 319.797 112.664C319.285 112.6 318.794 112.568 318.325 112.568H315.765V117.944Z" fill="#18113E" />
                </g>
            </g>
        </svg>


    )
}
