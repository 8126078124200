import helper from '../../utils/helper';
  
const fetchAndParseCsv = helper.fetchAndParseCsv;
const url =
  'https://docs.google.com/spreadsheets/d/1Vhhm-jfNRa2nTMfWFk4N6PQpVpO-Dz1p5lHZrPmPpaw/pub?gid=0&single=true&output=csv';

const toggleFilter = (dataList, value) =>
  dataList.includes(value)
    ? dataList.filter((item) => item !== value)
    : [...dataList, value];

// Fetch and Parse CSV Data
const getData = async () => {
     await fetchAndParseCsv(url)
};

// Error Filtering Logic
const filterError = (results, assetType, errorType) => {

  const errorFilters = {
    'No record in SAP': (res) =>
      res.source_layer === assetType &&
      res['Summary Concat']=== 'No record in SAP',

    'Object Type mismatch': (res) =>
      res.source_layer === assetType &&
      res['Summary Concat']=== 'Object type mismatch',
    'SAP Floc missing in GIS': (res) =>
      res.source_layer === assetType &&
      res['Summary Concat']=== 'SAP Floc missing in GIS',
      
    'Duplicate SAP keys in GIS': (res) =>
      res.source_layer === assetType &&
      res['Summary Concat']=== 'Duplicate SAP key'
  };

  const trueFilters = {
    'No record in SAP': (res) =>
      res.source_layer === assetType &&
      !res['Summary Concat'],
    'Object Type mismatch': (res) =>
      res.source_layer === assetType &&
      !res['Summary Concat'],

    'SAP Floc missing in GIS': (res) =>
      res.source_layer === assetType &&
      !res['Summary Concat'],

    'Duplicate SAP keys in GIS': (res) =>
      res.source_layer === assetType &&
      !res['Summary Concat'],
  };

  const checkFalse = results.filter(errorFilters[errorType] || (() => false));
  const checkTrue = results.filter(trueFilters[errorType] || (() => false));
  return { checkTrue, checkFalse };
};

// Add Filter
const addFilter = ({ key_, value, filterData }) => {
  const updatedFilterData = { ...filterData };
  updatedFilterData[key_] = toggleFilter(filterData[key_] || [], value);
  return updatedFilterData;
};

// Asset Type Table
const getAssetTypeTable = async (id) => {
  const results = await fetchAndParseCsv(url);
  const filterErrorType = localStorage.getItem('filterErrorType');
  const distinctTypes = Array.from(new Set(results.map((item) => item.source_layer)));
  const sourceLayer = distinctTypes[id];
  const { checkFalse } = filterError(results, sourceLayer, filterErrorType);
  return checkFalse;
};

// Asset Type Filter
const assetTypeFilter = ({ value, assetData }) => toggleFilter(assetData, value);

// Sync Filter
const syncFilter = (status) => {return status};

// Get Asset Types
const getAssetType = async (filterErrorType) => {
  const results = await fetchAndParseCsv(url);
  const distinctTypes = Array.from(new Set(results.map((item) => item.source_layer)));
  localStorage.setItem('filterErrorType', filterErrorType);
  return distinctTypes.map((type, index) => {
    const { checkTrue, checkFalse } = filterError(results, type, filterErrorType);
    return {
      type,
      falsedata: checkFalse,
      false: checkFalse.length,
      true: checkTrue.length,
      objectId: index,
    };
  });
};


// Error List
const getErrorList = async () => {
  const results = await fetchAndParseCsv(url);
  const errorCounts = results.reduce(
    (acc, res) => {
      if (
        res['Summary Concat']=== 'No record in SAP'
      ) {
        acc.no_record += 1;
      }
      if (
          res['Summary Concat']=== 'Object type mismatch'
      ) {
        acc.mismatch += 1;
      }
      if (
        res['Summary Concat']=== 'SAP Floc missing in GIS'
      ) {
        acc.sap_floc_missing_gi += 1;
      }
      if (
        res['Summary Concat']=== 'Duplicate SAP key'
      ) {
        acc.duplicate += 1;
      }
      console.log('acc', acc)
      return acc;
    },
    { no_record: 0, mismatch: 0, sap_floc_missing_gi: 0, duplicate:0 }
  );

  return errorCounts;
};

const errorTypeFilter = errortype =>{
  return errortype
}

// Data Service Object
const dataService = {
  getData,
  getAssetType,
  getErrorList,
  getAssetTypeTable,
  assetTypeFilter,
  addFilter,
  syncFilter,
  errorTypeFilter
};

export default dataService;
