import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGisData, getSapData } from '../features/overview/overviewSlice';
import OverviewCard from '../components/OverviewCard';
import Navigation from '../components/Navigation';
import KenDiagram from '../components/KenDiagram';
import SvgDiagram from '../components/SvgDiagram';
import SvgCard from '../components/SvgCard';

export default function OverviewStats() {
  const dispatch = useDispatch();


  // Data from Redux store
  const { gis, sap } = useSelector((state) => state.overview);

  // Fetch asset data on component mount
  useEffect(() => {
    dispatch(getGisData());
    dispatch(getSapData());
  }, [dispatch]);


  return (
    <>
      <Navigation current="overview" />
      <div className="overview container">
        <div className="row mt-5">
          <div className="col mt-4" style={{width:"calc(100% - 442px)"}}>
            {/* Overview Cards */}
            {
                gis ?
                <OverviewCard
                  date="03/10/2024"
                  totalErrors={gis.totalErrors}
                  totalRecords={gis.totalRecords}
                  percentageErrors={gis.percentageErrors}
                  title="GIS View"
                  subtitle="(SAP Assets Missing from GIS System)"
                  className="card mb-3"
                  href="/gis"
                  background="#71BE85"
                />:""
            }

            {
                sap ?
                <OverviewCard
                  date="29/09/2024"
                  totalErrors={sap.totalErrors}
                  totalRecords={sap.totalRecords}
                  percentageErrors={sap.percentageErrors}
                  title="SAP View"
                  subtitle="(GIS Assets Missing from SAP System)"
                  className="card "
                  href="/sap"
                  background="#0085CE"
                />:""
            }
            {/* Ken Diagram */}
            
          </div>
          <div className="col mt-4" style={{minWidth:"442px", maxWidth:"442px"}}>
            <SvgCard
            in_gis_but_missing_in_sap = {gis.in_gis_but_missing_in_sap}
            totalRecords = {gis.totalRecords}
            assets_with_errors={gis.assets_with_errors}
            in_sap_but_missing_in_gis = {sap.in_sap_but_missing_in_gis}
            />
          </div>
        </div>
      </div>
    </>
  );
}
